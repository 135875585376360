// Components/UserDropdown.js
import { Link } from 'react-router-dom'
import styles from '../Styles/UserDropdown.module.css'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

const UserDropdown = ({ onClose, onLogoutHandler }) => {
  return (
    <div className={styles.dropdown}>
      <div className={styles.userDetails}>
        <div className={styles.userCircle}>User</div>
        <div className={styles.userInfo}>
          <div className={styles.userName}>Test User</div>
          <div className={styles.userEmail}>testuser@example.com</div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <Link to="/settings" onClick={onClose}>
        Settings
      </Link>
      <div className={styles.divider}></div>
      <Link to="/studio" onClick={onClose}>
        Studio
      </Link>
      <div className={styles.divider}></div>
      <button className={styles.logoutButton} onClick={onLogoutHandler}>
        <ExitToAppIcon style={{ marginRight: '8px' }} />
        Log out
      </button>
    </div>
  )
}

export default UserDropdown
