import GameMakerContent from './GameMakerContent'
import Repository from './Repository'

const Content = ({ activePage }) => {
  return (
    <div>
      {activePage === 'game-maker' ? (
        <div style={{ marginLeft: '200px' }}>
          <GameMakerContent />
        </div>
      ) : (
        <div style={{ marginLeft: '200px', paddingTop: '80px' }}>
          <Repository />
        </div>
      )}
    </div>
  )
}

export default Content
