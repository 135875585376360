import styles from '../../Styles/TriviaQuizPage.module.css'
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  setTitle,
  setThumbnail,
  addQuestion,
  addQuestionsImages,
  resetQuiz,
} from '../../Features/Quiz/quizSlice'
import { saveQuiz } from '../../Services/APi/QuizApi'

import {
  MAX_FILE_SIZE,
  ALLOWED_FILE_TYPES,
  QUIZ_BACKGROUND_IMAGE_PATH,
} from '../../Configuration/Constants'

const TriviaQuizPage = () => {
  const imagePath = process.env.PUBLIC_URL + '/DefaultImages/defaultTheme.png'

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const questions = useSelector((state) => state.triviaQuiz.questions)

  const quizState = useSelector((state) => state.triviaQuiz)

  // Popup windows
  const [showPopup, setShowPopup] = useState(true)
  const [showPopupPic, setShowPopupPic] = useState(false)
  const [quizTitle, setQuizTitle] = useState('') // New state for the quiz title
  const [imageFile, setImageFile] = useState(null)

  // Main part of the app
  const [picture, setPicture] = useState(null)
  const [questionType, setQuestionType] = useState('MCQ') //other is TrueFalse
  const [question, setQuestion] = useState('')
  const [options, setOptions] = useState([])
  const [showAddButton, setShowAddButton] = useState(false)
  const [enableSaveButton, setEnableSaveButton] = useState(false)

  const [questionsImages, setQuestionsImages] = useState([])

  const fileInputRef = useRef(null)

  useEffect(() => {
    // This function will be executed when the component is unmounted
    return () => {
      dispatch(resetQuiz())
    }
  }, [dispatch])

  // Handle quiz title submission
  const handleTitleSubmit = (e) => {
    e.preventDefault()
    dispatch(setTitle(quizTitle))
    setShowPopup(false)
    setShowPopupPic(true)
  }

  // Handle quiz title change
  const handleTitleChange = (e) => {
    const title = e.target.value
    setQuizTitle(title)
  }

  // Navigate to the previous page
  const handleCancelClick = () => {
    navigate(-1)
  }

  // Handle file submission - first oicture in popup
  const handleFileSubmit = (e) => {
    e.preventDefault()
    //dispatch(setThumbnail(imageFile))
    setShowPopupPic(false)
  }

  // Handle file change and basic error handling
  const isValidFile = (file) => {
    if (ALLOWED_FILE_TYPES.includes(file.type) && file.size <= MAX_FILE_SIZE) {
      return true
    }
    return false
  }

  const handleFileChange = (e, setFile, context) => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return

    if (!isValidFile(selectedFile)) {
      alert('Invalid file type or size.')
      return
    }

    if (context === 'thumbnail') {
      setFile(selectedFile)
    } else if (context === 'questionImage') {
      setFile(selectedFile)

      const questionNumber = questions.length + 1
      console.log('The length of question is: ', questions.length)
      const newImage = {
        questionNo: questions.length + 1, // derive the question number
        image: selectedFile,
      }

      setQuestionsImages((prevImages) => {
        const exisitngImageIndex = prevImages.findIndex(
          (img) => img.questionNo === questionNumber,
        )

        if (exisitngImageIndex !== -1) {
          return prevImages.map((img, index) =>
            index === exisitngImageIndex
              ? { ...img, image: selectedFile }
              : img,
          )
        } else {
          return [...prevImages, newImage]
        }
      })
    }
  }

  useEffect(() => {
    if (questionType === 'MCQ') {
      setOptions(
        Array.from({ length: 4 }, () => ({ option: '', isCorrect: false })),
      )
    } else {
      setOptions([
        { option: '', isCorrect: false },
        { option: '', isCorrect: false },
      ])
    }
  }, [questionType])

  // Handle question text change
  const handleQuestionChange = (e) => {
    setQuestion({ question: e.target.value, questionNo: questions.length + 1 })
    //validateForm()
  }

  useEffect(() => {
    validateForm()
    console.log('Question type: ' + questionType)
    console.log('Quiz title: ' + quizTitle)
    console.log('Answers: ')
    options.map((item) => {
      console.log(item.option + ' ' + item.isCorrect)
    })
  }, [question, options])

  // Handle answers text change
  const handleOptionChange = (index, value, isCorrect = false) => {
    const newOptions = options.map((opt, idx) => {
      if (idx === index) {
        return { option: value, isCorrect }
      } else if (isCorrect) {
        return { option: opt.option, isCorrect: false }
      } else {
        return opt
      }
    })
    setOptions(newOptions)
    //validateForm()
  }

  //Validate if all mandatory fields are filled to show the 'Add' button
  const validateForm = () => {
    const filledOptions = options.filter((opt) => opt.option)
    const correctOptions = options.filter((opt) => opt.isCorrect)

    if (
      question &&
      filledOptions.length === options.length &&
      correctOptions.length === 1
    ) {
      setShowAddButton(true)
    } else {
      setShowAddButton(false)
    }
  }

  //Add question to REDUX
  const handleAddQuestion = () => {
    console.log('Question is: ', question)
    console.log('Question type is: ', questionType)
    console.log('Options are: ', options)
    dispatch(addQuestion({ question, questionType, options }))

    if (picture) {
      const questionNumber = questions.length + 1
      // dispatch(
      //   addQuestionsImages({ questionNo: questionNumber, image: picture }),
      // )
    }
    // Reset fields for the next question
    setQuestion({ question: '', questionNo: '' })
    setPicture(null)
    setQuestionType('MCQ')
    setOptions(
      Array.from({ length: 4 }, () => ({ option: '', isCorrect: false })),
    )

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    setShowAddButton(false)
  }

  const showAddedQuestionInSidePanel = () => {
    console.log('Added Questions:', questions)
    questions.forEach((question, index) => {
      console.log(`Question ${index + 1}:`, question.question)
      question.options.forEach((option, idx) => {
        console.log(
          `Option ${idx + 1}:`,
          option.option,
          option.isCorrect ? '(Correct)' : '(Wrong)',
        )
      })
    })
  }

  useEffect(() => {
    console.log('____________________________________________')
    showAddedQuestionInSidePanel()
    if (questions.length > 0) {
      setEnableSaveButton(true)
    } else {
      setEnableSaveButton(false)
    }
  }, [questions])

  const handleSaveQuiz = async () => {
    console.log('Quiz is being saved...')
    console.log(quizState)
    try {
      const result = await saveQuiz(quizState, imageFile, questionsImages)
      if (result.status === 400 && result.data.success === true) {
        console.log('Quiz saved but not all/some pictures: ', result)
      } else if (result.status === 400 && result.data.success === false) {
        console.log('Something went wrong ', result.data.message)
      } else if (result.status === 200) {
        console.log(result.data.message)
      }
      dispatch(resetQuiz())
      navigate('/studio/repository')
    } catch (error) {
      console.error('Failed to save quiz. ', error)
    }
  }

  return (
    <div className={styles.generalContainer}>
      {/* Title Input */}
      {showPopup && (
        <div className={styles.backdrop}>
          <div className={styles.formBox}>
            <div>
              <div className={styles.mainText}>Let's start it!</div>
              <div className={styles.secondaryText}>STEP 1</div>
              <form onSubmit={handleTitleSubmit}>
                <input
                  className={styles.inputTitle}
                  type="text"
                  placeholder="Enter trivia quiz title"
                  value={quizTitle} // Controlled component
                  onChange={handleTitleChange} // Function to handle title change
                ></input>
                <button
                  className={styles.continueBtn}
                  type="submit"
                  disabled={!quizTitle} // Button will be disabled if quizTitle is empty
                >
                  Continue
                </button>
              </form>
              <button
                className={styles.cancelBtn}
                type="reset"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Thumbnail Input */}
      {showPopupPic && (
        <div className={styles.backdrop}>
          <div className={styles.formBox}>
            <div>
              <div className={styles.mainText}>Let's start it!</div>
              <div className={styles.secondaryText}>STEP 2</div>
              <div className={styles.secondaryText}>
                Upload a thumbnail of your quiz - optional
              </div>
              <form onSubmit={handleFileSubmit} className={styles.formElement}>
                {imageFile && (
                  <img
                    src={URL.createObjectURL(imageFile)}
                    alt="Preview"
                    className={styles.imagePreview}
                  />
                )}
                <div className={styles.fileInputContainer}>
                  <label
                    className={styles.customFileLabel}
                    htmlFor="customFile"
                  >
                    Choose file
                  </label>
                  <input
                    id="customFile"
                    type="file"
                    className={styles.customFileInput}
                    onChange={(e) =>
                      handleFileChange(e, setImageFile, 'thumbnail')
                    }
                  ></input>
                </div>
                <div>
                  <button className={styles.continueBtn} type="submit">
                    Continue
                  </button>
                </div>
              </form>
              <button
                className={styles.cancelBtn}
                type="reset"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={`${styles.leftPanel} ${styles.generalPanelSetting}`}>
        <div className={styles.leftPanelTitle}>Your questions</div>
        {questions.map((q, index) => (
          <div key={index} className={styles.questionBoxLeftPanel}>
            Question {index + 1}
          </div>
        ))}
      </div>
      <div className={`${styles.middlePanel} ${styles.generalPanelSetting}`}>
        {/* Quiz Title and Thumbnail */}
        <div className={styles.quizInfo}>
          <div className={styles.titleQuiz}>{quizTitle}</div>
          {imageFile && (
            <img
              src={URL.createObjectURL(imageFile)}
              alt="thumbnail"
              className={styles.thumbnail}
            />
          )}
        </div>

        {/* Question Input */}
        <div
          className={styles.questionContainer}
          style={{ backgroundImage: `url(${imagePath})` }}
        >
          <input
            type="text"
            placeholder="Enter question"
            value={question.question}
            onChange={handleQuestionChange}
            className={styles.questionInput}
          />

          {/* Picture Upload */}
          <div className={styles.pictureUpload}>
            <input
              type="file"
              ref={fileInputRef}
              id="questionPicture"
              className={styles.myInput}
              onChange={(e) => handleFileChange(e, setPicture, 'questionImage')}
            />
            {picture && (
              <img
                src={URL.createObjectURL(picture)}
                alt="questionPicture"
                className={styles.picturePreview}
              />
            )}
          </div>

          {/* Options */}
          <div className={styles.optionContainer}>
            {options &&
              options.map((option, index) => (
                <div key={index} className={styles.optionBox}>
                  <input
                    type="radio"
                    name="correctOption"
                    className={styles.radioButton}
                    checked={option.isCorrect}
                    onChange={() =>
                      handleOptionChange(index, option.option, true)
                    }
                  />
                  <input
                    type="text"
                    className={styles.option}
                    value={option.option}
                    placeholder="Answer"
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                  />
                </div>
              ))}
          </div>
        </div>
        {/* Add Button */}
        {showAddButton && (
          <button className={styles.addButton} onClick={handleAddQuestion}>
            Add
          </button>
        )}

        {questionsImages.map((imageObj) => (
          <div key={imageObj.questionNo}>
            <h3>Question {imageObj.questionNo}</h3>
            <img
              src={URL.createObjectURL(imageObj.image)}
              alt={`Question Image ${imageObj.questionNo}`}
            />
          </div>
        ))}

        {/* <p>
          EduApp is the premier platform for educators dedicated to crafting
          comprehensive and interactive courses. Elevate the learning experience
          for your students by harnessing the power of modern tools and
          methodologies. Step into the future of education, where every lesson
          is an interactive journey. Join us today and set the standard for
          academic excellence.
        </p>
        <p>
          EduApp is the premier platform for educators dedicated to crafting
          comprehensive and interactive courses. Elevate the learning experience
          for your students by harnessing the power of modern tools and
          methodologies. Step into the future of education, where every lesson
          is an interactive journey. Join us today and set the standard for
          academic excellence.
        </p>

        <p>
          EduApp is the premier platform for educators dedicated to crafting
          comprehensive and interactive courses. Elevate the learning experience
          for your students by harnessing the power of modern tools and
          methodologies. Step into the future of education, where every lesson
          is an interactive journey. Join us today and set the standard for
          academic excellence.
        </p>

        <p>
          EduApp is the premier platform for educators dedicated to crafting
          comprehensive and interactive courses. Elevate the learning experience
          for your students by harnessing the power of modern tools and
          methodologies. Step into the future of education, where every lesson
          is an interactive journey. Join us today and set the standard for
          academic excellence.
        </p> */}
      </div>
      <div className={`${styles.rightPanel} ${styles.generalPanelSetting}`}>
        <button
          type="button"
          className={styles.saveBtn}
          disabled={!enableSaveButton}
          onClick={handleSaveQuiz}
        >
          Save
        </button>
      </div>
    </div>
  )
}
export default TriviaQuizPage
