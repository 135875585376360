import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  title: '',
  thumbnail: '',
  type: 'trivia-quiz-game',
  questions: [],
  questionsImages: [],
}

export const quizSlice = createSlice({
  name: 'trivia-quiz',
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload
    },
    setThumbnail: (state, action) => {
      state.thumbnail = action.payload
    },
    addQuestion: (state, action) => {
      state.questions.push(action.payload)
    },
    addQuestionsImages: (state, action) => {
      state.questionsImages.push(action.payload)
    },
    resetQuiz: () => {
      return initialState
    },
  },
})

export const {
  setTitle,
  setThumbnail,
  addQuestion,
  addQuestionsImages,
  resetQuiz,
} = quizSlice.actions
export default quizSlice.reducer
