import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//THE BELOW CODE IS IF YOU WANT TO USE STRICT MODE DURING DEV MODE
//I had to disable due to run effect running twice in TriviaQuizGame which was deleting my row in db immiedtiatly due to cleanup function
{
  /* <StrictMode>
    <App />
  </StrictMode>, */
}
reportWebVitals()
