import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './Contexts/AuthContext'
import './Styles/global.css'
import Navbar from './Components/Navbar'
import Sidebar from './Components/Sidebar'
import AppRoutes from './Components/AppRoutes'
import RouteGuard from './Components/RouteGuard'
import store from './Services/store'
import { Provider } from 'react-redux'
import PlayerGameView from './Components/PlayerGameView'

function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/game-viewer" element={<PlayerGameView />} />
            <Route
              path="/*"
              element={
                <div>
                  <Navbar />
                  <Sidebar />
                  <RouteGuard>
                    <AppRoutes />
                  </RouteGuard>
                </div>
              }
            />
          </Routes>
        </Router>
      </Provider>
    </AuthProvider>
  )
}

export default App
