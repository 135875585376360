import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom' // Import Link for navigation
import {
  retrieveUserQuizes,
  insertActiveQuizUser,
} from '../Services/APi/QuizApi'
import styles from '../Styles/Repository.module.css'
import {
  TRIVIA_QUIZ_DEFAULT_THUMBNAIL,
  API_BASE_URL,
} from '../Configuration/Constants'

const Repository = () => {
  const [userQuizzes, setUserQuizzes] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await retrieveUserQuizes()
        console.log('API Response:', data)
        setUserQuizzes(data.quizData)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, []) // The empty array means this useEffect will run once when the component mounts

  const handleInsertUserQuiz = async (quizType, quizID) => {
    try {
      console.log('Repo: handler: quiz id: ', quizID)
      const result = await insertActiveQuizUser(quizID)

      if (result.success) {
        console.log('Inserted user id and quiz id')
        navigate(`/studio/repository/${quizType}`)
      } else {
        alert(result.message)
      }
    } catch (error) {
      console.log('Error inserting active game for the user')
      alert(error.message)
    }
  }

  if (loading)
    return <p style={{ marginLeft: '200px', paddingTop: '71px' }}>Loading...</p>

  if (error)
    return (
      <p style={{ marginLeft: '200px', paddingTop: '71px' }}>
        Error: {error.message}
      </p>
    )

  return (
    <div className={styles.general}>
      <div className={styles.mainText}>Your games</div>
      <div className={styles.parentContainer}>
        {userQuizzes.map((quiz, index) => (
          <div className={styles.gameButton} key={index}>
            <h3 className={styles.titleGame}>{quiz.Title}</h3>
            <img
              className={styles.imageWrapper}
              src={
                quiz.Path_Thumbnail
                  ? (() => {
                      const imageUrl = `${API_BASE_URL}/quiz/images/${quiz.Path_Thumbnail}`
                      console.log(imageUrl)
                      return imageUrl
                    })()
                  : TRIVIA_QUIZ_DEFAULT_THUMBNAIL
              }
              alt={quiz.Title}
              onError={(e) => {
                e.target.onerror = null
                e.target.src = TRIVIA_QUIZ_DEFAULT_THUMBNAIL
              }}
            />
            <div className={styles.buttonContainer}>
              <button
                to={`/studio/repository/${quiz.Type}`}
                className={`${styles.circleButton} ${styles.playButton}`}
                onClick={() => handleInsertUserQuiz(quiz.Type, quiz.Quiz_ID)}
              >
                <i className="fas fa-play"></i>
              </button>
              <div className={styles.rightButtons}>
                <button
                  className={`${styles.circleButton} ${styles.editButton}`}
                >
                  <i className="fas fa-edit"></i>
                </button>
                <button
                  className={`${styles.circleButton} ${styles.deleteButton}`}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            </div>

            {/* Add any other quiz details you want to display */}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Repository
