import axios from 'axios'
import { API_BASE_URL, TOKEN_KEY } from '../../Configuration/Constants'

export const saveQuiz = async (quizData, thumbnail, questionImages) => {
  try {
    const formData = new FormData()
    formData.append('title', quizData.title)
    formData.append(
      'thumbnail',
      thumbnail,
      'thumbnail.' + thumbnail.name.split('.').pop(),
    )
    formData.append('type', quizData.type)
    formData.append('questions', JSON.stringify(quizData.questions))

    //Append files separatly
    questionImages.forEach((imageData, index) => {
      const extension = imageData.image.name.split('.').pop()
      formData.append(
        imageData.questionNo,
        imageData.image,
        `${imageData.questionNo}.${extension}`,
      )
    })
    const token = localStorage.getItem(TOKEN_KEY)
    // const response = await axios.post(
    //   `${API_BASE_URL}/quiz/createQuiz`,
    //   quizData,
    //   {
    //     headers: {
    //       Authorization: 'Bearer ' + token,
    //     },
    //   },
    // )

    const response = await axios.post(
      `${API_BASE_URL}/quiz/createQuiz`,
      formData,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return response.data
  } catch (error) {
    console.log('Error saving quiz: ', error)
    throw error
  }
}

export const retrieveUserQuizes = async () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY)
    const response = await axios.get(
      `${API_BASE_URL}/quiz/retrieveUserQuizes`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    )
    return response.data
  } catch (error) {
    console.log('Error retrieving quizes: ', error)
    throw error
  }
}

export const insertActiveQuizUser = async (quizID) => {
  try {
    console.log('Quiz api: inserting: quiz id: ', quizID)
    const token = localStorage.getItem(TOKEN_KEY)
    const response = await axios.post(
      `${API_BASE_URL}/quiz/insertActiveQuizUser`,
      { quizID: quizID },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    )
    return response.data.result
  } catch (error) {
    console.log('Error retrieving quizes: ', error)
    throw error
  }
}
export const deleteActiveUserAndQuiz = async () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY)
    const response = await axios.post(
      `${API_BASE_URL}/quiz/deleteActiveUserAndQuiz`,
      {},
      { headers: { Authorization: 'Bearer ' + token } },
    )
    console.log('Response from deleting in API is : ', response)
    return response
  } catch (error) {
    console.log('Error deleting user and quiz: ', error)
    return { data: null, error: error.response?.data }
  }
}
