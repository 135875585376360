export const MAX_FILE_SIZE = 2000000 // 2MB
export const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/jpg']
export const QUIZ_BACKGROUND_IMAGE_PATH = '/path/to/your/background/image.jpg'
export const TRIVIA_QUIZ_DEFAULT_THUMBNAIL =
  '/DefaultImages/triviaQuizDefault.png'

export const API_BASE_URL = 'https://mygames.mostvalueplay.eu/api'

export const API_URL_SOCKET_IO = 'https://mygames.mostvalueplay.eu'

//LOCAL DEV

// export const API_BASE_URL = 'http://localhost:5000/api'

// export const API_URL_SOCKET_IO = 'http://localhost:5000'

export const TOKEN_KEY = 'userToken'
