import { useState, useEffect, useRef } from 'react'
import io from 'socket.io-client'
import { API_URL_SOCKET_IO } from '../Configuration/Constants'
import styles from '.././Styles/PlayerGameView.module.css'

const PlayerGameView = () => {
  const [roomCode, setRoomCode] = useState('')
  const [username, setUsername] = useState('')
  const [isJoined, setIsJoined] = useState(false)
  const [question, setQuestion] = useState(null)
  const [selectedAnswer, setSelectedAnswer] = useState(-1)
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null)
  const [resultDisplay, setResultDisplay] = useState(false)
  const [error, setError] = useState('')
  const [socket, setSocket] = useState(null)
  const [selectedBtn, setSelectedBtn] = useState(null)
  const [score, setScore] = useState(0)
  const [loading, setLoading] = useState(false)

  const selectedAnswerRef = useRef(selectedAnswer)
  useEffect(() => {
    selectedAnswerRef.current = selectedAnswer
  }, [selectedAnswer])

  const questionRef = useRef()
  useEffect(() => {
    questionRef.current = question
  }, [question])

  const scoreRef = useRef(score)
  useEffect(() => {
    scoreRef.current = score
  }, [score])

  useEffect(() => {
    const newSocket = io(API_URL_SOCKET_IO) // Create the socket connection here
    setSocket(newSocket) // Set the socket to the state

    // const handleValidRoom = (isValid) => {
    //   if (isValid) {
    //     setIsJoined(true)
    //     setLoading(true)
    //   } else {
    //     alert('Invalid room code!')
    //   }
    // }

    const handleNewQuestion = (newQuestion) => {
      setQuestion(newQuestion)
    }

    const handleRoomJoined = (response) => {
      console.log('Response after joining the room: ', response)
      if (response.success) {
        setIsJoined(true)
        setLoading(true)
      } else {
        alert('There was a problem. Please try again')
      }
    }

    const handleError = (error) => {
      setError(error)
      alert(error)
      console.log(error)
    }
    const handleNewQuestionAppearance = (data) => {
      setLoading(false)
      setSelectedAnswer(-1)
      setSelectedBtn(null)
      console.log(data.message)
      console.log(data.questionData)
      setResultDisplay(false)
      setQuestion(data.questionData)
    }

    const handleQuestionDone = () => {
      console.log('Selected Answer', selectedAnswerRef.current)

      // Check if questionRef.current is not null and selectedAnswerRef.current is a valid index
      if (
        questionRef.current &&
        selectedAnswerRef.current >= 0 &&
        selectedAnswerRef.current < questionRef.current.options.length
      ) {
        setIsAnswerCorrect(
          questionRef.current.options[selectedAnswerRef.current]?.isCorrect,
        )
        if (questionRef.current.options[selectedAnswerRef.current]?.isCorrect) {
          console.log('Score now is: ', score)
          setScore((s) => s + 10)
          console.log('Score 2 now is: ', score)
        }
      } else {
        // Handle the case where selectedAnswerRef.current is not a valid index
        setIsAnswerCorrect(false)
        console.error('Invalid answer index:', selectedAnswerRef.current)
      }

      if (selectedBtn === null) {
        setSelectedBtn(-1)
      }
      setResultDisplay(true)
    }

    //newSocket.on('validRoom', handleValidRoom)
    newSocket.on('newQuestion', handleNewQuestion)
    newSocket.on('roomJoined', handleRoomJoined)
    newSocket.on('error', handleError)
    newSocket.on('questionUpdate', handleNewQuestionAppearance)
    newSocket.on('questionDone', handleQuestionDone)

    return () => {
      //newSocket.off('validRoom', handleValidRoom)
      newSocket.off('newQuestion', handleNewQuestion)
      newSocket.off('roomJoined', handleRoomJoined)
      newSocket.off('error', handleError)
      newSocket.off('questionUpdate', handleNewQuestionAppearance)
      newSocket.off('questionDone', handleQuestionDone)
      newSocket.disconnect() // Disconnect the socket when the component unmounts
    }
  }, [])

  const handelJoin = () => {
    socket.emit('joinRoom', { roomCode, username })
  }

  const isJoinButtonDisabled = () => {
    return username.length < 2 || roomCode.length !== 8
  }

  const handleSubmitAnswer = (answer, answerID, index) => {
    setSelectedAnswer((prevAnswer) => index)
    //console.log('Selected Answer', selectedAnswer)
    console.log('Answer id is: ', answerID)
    console.log('Index is: ', index)
    console.log('Option selected is: ', question.options[index])
    console.log(question.options[index].isCorrect)
    socket.emit('submitAnswer', {
      roomCode,
      username,
      quizID: question.quizID,
      questionID: question.questionID,
      answerID,
    })
  }

  const handleSelectedButton = (index) => {
    setSelectedBtn(index)
  }

  if (!isJoined) {
    return (
      <div className={styles.layout}>
        <input
          type="text"
          placeholder="Room code"
          value={roomCode}
          onChange={(e) => {
            setRoomCode(e.target.value)
          }}
        />
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value)
          }}
        />

        <button
          type="button"
          onClick={handelJoin}
          disabled={isJoinButtonDisabled()}
        >
          JOIN
        </button>
      </div>
    )
  }

  return (
    <div>
      {loading ? (
        <>
          <div className={styles.spinner}></div>
          <div>It is loading and spinner should be on</div>
        </>
      ) : resultDisplay ? (
        <div>
          {isAnswerCorrect ? (
            <>
              <div>Your answer is correct!</div>
              <div>+10</div>
              <div>Total Score: {score}</div>
            </>
          ) : (
            <>
              <div>Your answer is incorrect!</div>
              <div>+0</div>
              <div>Total Score: {score}</div>
            </>
          )}
        </div>
      ) : (
        <div className={styles.layout}>
          {question?.options.map((option, index) => (
            <button
              className={`${styles.button} ${
                selectedBtn === index ? styles.selected : ''
              }`}
              key={index}
              value={option.optionText}
              disabled={selectedAnswer !== -1}
              onClick={(event) => {
                handleSubmitAnswer(event.target.value, option.optionID, index)
                handleSelectedButton(index)
              }}
            >
              {option.optionText}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default PlayerGameView
