import React from 'react'
import styles from '../Styles/Home.module.css'

const Home = () => {
  return (
    <div>
      <div className={styles.homeHeader}>
        <div className={styles.introText}>
          <h1>Welcome to Our Educational Platform</h1>
          <p>
            EduApp is the premier platform for educators dedicated to crafting
            comprehensive and interactive courses. Elevate the learning
            experience for your students by harnessing the power of modern tools
            and methodologies. Step into the future of education, where every
            lesson is an interactive journey. Join us today and set the standard
            for academic excellence.
          </p>
        </div>

        <div className={styles.introImage}>
          <img src="path_to_your_image.jpg" alt="Some descriptive text" />
        </div>
      </div>

      {/* Other content of your homepage */}
    </div>
  )
}

export default Home
