import { Link, useNavigate } from 'react-router-dom'
import { useState, useRef, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../Contexts/AuthContext'
import styles from '../Styles/Navbar.module.css'
import UserDropdown from './UserDropdown'

const Navbar = () => {
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const menuRef = useRef(null)

  const location = useLocation()

  const navigate = useNavigate()

  const { isLoggedIn, logout } = useAuth()

  const handleLogoutClick = () => {
    setMenuVisibility(false)
    logout()
    navigate('/')
  }

  const toggleMenuVisibility = () => {
    setMenuVisibility(!isMenuVisible)
  }

  //The handleClickOutside function checks if a click event happened outside the dropdown menu
  //The useCallback hook returns a memoized version of the callback function that only changes if one of its dependencies has changed. -> optimazation
  const handleClickOutside = useCallback((event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisibility(false)
    }
  }, [])

  //As soon as the component mounts (like componentDidMount), the document.addEventListener('mousedown', handleClickOutside); is executed.
  //This sets up a global click listener that will trigger the handleClickOutside function whenever a mousedown event happens on the document.
  //The return function includes a cleanup function that removes the listener to prevent memory leaks or unwanted behaviors, it will run before the component unmounts or if the list of dependencied changed
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <div className={styles.navbar}>
      <div className={styles.leftContent}>
        <img
          src="/favicon-100x60.png"
          alt="Website logo"
          className={styles.logo}
        ></img>
        <span className={styles.logoText}>EduApp</span>
      </div>
      <div className={styles.rightContent}>
        <Link
          to="/"
          className={location.pathname === '/' ? styles.selected : ''}
        >
          Home
        </Link>
        <Link
          to="/about"
          className={location.pathname === '/about' ? styles.selected : ''}
        >
          About
        </Link>
        <Link
          to="/contact"
          className={location.pathname === '/contact' ? styles.selected : ''}
        >
          Contact
        </Link>
        <Link
          to="/help"
          className={location.pathname === '/help' ? styles.selected : ''}
        >
          Help
        </Link>
        {isLoggedIn ? (
          <div ref={menuRef}>
            <button className={styles.dropBtn} onClick={toggleMenuVisibility}>
              User
            </button>
            {console.log(isMenuVisible)}
            {isMenuVisible && (
              <UserDropdown
                onLogoutHandler={handleLogoutClick}
                onClose={() => setMenuVisibility(false)}
              />
            )}
          </div>
        ) : (
          <div>
            <Link
              to="/login"
              className={location.pathname === '/login' ? styles.selected : ''}
            >
              Login
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar
