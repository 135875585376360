import styles from '../Styles/GameMakerContent.module.css'
import GameSelectTypeBtn from './GameEditorComponents/GameSelectTypeBtn'

const GameMakerContent = () => {
  const gameTypes = [
    {
      type: 'trivia-quiz',
      imagePath: process.env.PUBLIC_URL + '/DefaultImages/trivia-quiz.png',
    },
    {
      type: 'escape-room',
      imagePath: process.env.PUBLIC_URL + '/DefaultImages/escape-room.png',
    },
    // Add more game types here as needed
  ]

  console.log('Current URL:', window.location.pathname)

  return (
    <div className={styles.gameMakerContent}>
      <div className={`${styles.title} ${styles.general}`}>
        Welcome back ...!
      </div>
      <div className={`${styles.introText} ${styles.general}`}>
        Discover the Wonders of Learning with "QuestMinds"!
      </div>
      <div className={`${styles.secondaryText} ${styles.general}`}>
        Dive into the vibrant universe of knowledge, where every question
        unlocks a realm of wonder!
      </div>
      <div className={`${styles.gridContainer} ${styles.general}`}>
        {gameTypes.map((gameType, index) => (
          <GameSelectTypeBtn key={index} gameType={gameType} />
        ))}
      </div>
    </div>
  )
}

export default GameMakerContent
