import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from '../Styles/Sidebar.module.css'
import { useAuth } from '../Contexts/AuthContext'

const Sidebar = () => {
  const location = useLocation()
  const [selectedItem, setSelectedItem] = useState('')
  const { isLoggedIn } = useAuth()

  useEffect(() => {
    if (location.pathname.startsWith('/studio/game-maker')) {
      setSelectedItem('game-maker')
    } else if (location.pathname.startsWith('/studio/repository')) {
      setSelectedItem('repository')
    }
  }, [location.pathname])

  if (location.pathname.startsWith('/studio') && isLoggedIn) {
    return (
      <div className={styles.sidebar}>
        <Link
          to="/studio/game-maker"
          className={selectedItem === 'game-maker' ? 'selected' : ''}
        >
          Game maker
        </Link>
        <div className={styles.divider}></div>
        <Link
          to="/studio/repository"
          className={selectedItem === 'repository' ? 'selected' : ''}
        >
          Repository
        </Link>
        <div className={styles.divider}></div>
      </div>
    )
  } else {
    return null
  }
}

export default Sidebar
