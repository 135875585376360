import React, { createContext, useContext, useState, useEffect } from 'react'
import { TOKEN_KEY } from '../Configuration/Constants'

const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // Check if the user is authenticated when the app initializes
    const token = localStorage.getItem(TOKEN_KEY)

    if (token) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }

    // Set isLoading to false after checking the initial state
    setIsLoading(false)
  }, [])

  const login = (token) => {
    localStorage.setItem(TOKEN_KEY, token)
    setIsLoggedIn(true)
  }

  const logout = () => {
    localStorage.removeItem(TOKEN_KEY)
    setIsLoggedIn(false)
  }

  const isAuthenticated = () => {
    // Check if the user is authenticated
    return isLoggedIn
  }

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, login, logout, isAuthenticated, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  )
}
