import { API_BASE_URL, TOKEN_KEY } from '../Configuration/Constants'
import { useRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { deleteActiveUserAndQuiz } from '../Services/APi/QuizApi'
import styles from '../Styles/TriviaQuizGame.module.css'
import CustomModal from './CustomModal'

const TriviaQuizGame = () => {
  const [deletedRow, setDeletedRow] = useState(false)
  const iframeRef = useRef(null)
  const navigation = useNavigate()
  const [modalOpen, setModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const token = localStorage.getItem(TOKEN_KEY)
  const sendConfig = () => {
    console.log('I am in the func definition of send user token ')
    const unityIframe = document.getElementById('unity-iframe')
    unityIframe.contentWindow.postMessage(
      {
        type: 'UNITY_ACTION',
        payload: { token: token, baseURL: API_BASE_URL },
      },
      '*',
    )
  }

  useEffect(() => {
    console.log('Component mountewd')
    if (!iframeRef.current) {
      console.log('I am in if statement of iframeRef being null')
      const iframe = document.createElement('iframe')
      iframe.src = '/April9thServerBuild_V2/index.html' //change the xxx to the name of your build
      iframe.style.width = '960px'
      //iframe.style.height = '56.25vw' // 9/16 * 100% = 56.25%
      iframe.style.height = '590px'
      iframe.style.border = 'none'
      iframe.style.overflow = 'hidden'
      iframe.id = 'unity-iframe'
      iframeRef.current = iframe
      document.getElementById('unity-container').appendChild(iframe)
      iframe.onload = () => {
        console.log('I am in onLoad of sendUserToken')
        sendConfig()
      }
    }
    return () => {
      console.log('component unmouting')
      if (iframeRef.current) {
        iframeRef.current.remove()
        iframeRef.current = null
      }

      setDeletedRow(true)

      //ASYNC func
      const handleDeleteUserAndQuiz = async (token) => {
        const { data, error } = await deleteActiveUserAndQuiz(token)
        console.log('Data is: ', data)
        console.log('Error is: ', error)
        if (data && data.result.success) {
          console.log('Deleted user and quiz')
        } else if (error) {
          console.log('Error deleting active game for the user:', error)
          setModalMessage(error)
          setModalOpen(true)
        } else {
          setModalMessage(error)
          setModalOpen(true)
        }
      }
      if (!deletedRow) {
        console.log('The row was not deleted')
        console.log('The row deleted value: ', deletedRow)
        handleDeleteUserAndQuiz(token)
      } else {
        console.log('The row was deleted')
        console.log('The row deleted value: ', deletedRow)
      }
    }
  }, [])
  const HandleReturnClick = () => {
    //Prev page
    navigation(-1)
  }
  return (
    <div style={{ paddingTop: '71px', marginLeft: '225px' }}>
      <h2>Course Details: </h2>
      <div id="unity-container"></div>
      <button className={styles.returnButton} onClick={HandleReturnClick}>
        Return
      </button>
      <CustomModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        message={modalMessage}
      />
    </div>
  )
}
export default TriviaQuizGame
