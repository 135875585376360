import React from 'react'
import { Link } from 'react-router-dom' // Import Link for navigation
import styles from '../../Styles/GameSelectTypeBtn.module.css'

const GameSelectTypeBtn = ({ gameType }) => {
  const { type, imagePath } = gameType

  return (
    <Link to={`/studio/game-maker/${type}`} className={styles.gameButton}>
      <div className={styles.imageWrapper}>
        <img src={imagePath} alt={type} className={styles.image} />
      </div>
      <div className={styles.plusCircle}>
        <span className={styles.plusIcon}>+</span>
      </div>
      <div className={styles.title}>{`Create a new ${type}`}</div>
    </Link>
  )
}

export default GameSelectTypeBtn
