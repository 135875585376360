import React from 'react'
import styles from '../Styles/CustomModal.module.css'

const CustomModal = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <p>{message}</p>
        <button className={styles.closeButton} onClick={onClose}>
          OK
        </button>
      </div>
    </div>
  )
}
export default CustomModal
