import { Routes, Route } from 'react-router-dom'
import StudioRoutes from './StudioRoutes'
import Login from '../Pages/Login'
import Home from '../Pages/Home'
import TriviaQuizPage from './GameEditorComponents/TriviaQuizPage'
import EscapeRoomPage from './GameEditorComponents/EscapeRoomPage'
import TriviaQuizGame from './TriviaQuizGame'

const AppRoutes = () => (
  <Routes>
    <Route path="/about" element={<div>About Page</div>} />
    <Route path="/contact" element={<div>Contact Page</div>} />
    <Route path="/help" element={<div>Help Page</div>} />
    <Route path="/studio/*" element={<StudioRoutes />} />
    <Route path="/login" element={<Login />} />
    <Route path="/settings" element={<div>Settings</div>} />
    <Route
      path="/studio/repository/trivia-quiz-game"
      element={<TriviaQuizGame />}
    />
    <Route path="/studio/game-maker/trivia-quiz" element={<TriviaQuizPage />} />
    <Route path="/studio/game-maker/escape-room" element={<EscapeRoomPage />} />

    <Route path="/" element={<Home />} />
  </Routes>
)

export default AppRoutes
