// src/Services/Api/authService.js
import axios from 'axios'
import { API_BASE_URL } from '../../Configuration/Constants'

export const login = async (username, password) => {
  console.log(
    'FROMAPI CALL: username is: ',
    username,
    '  Password is: ',
    password,
  )
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/login`, {
      username,
      password,
    })

    const token = response.data.token
    console.log('Token is: ', token)
    //localStorage.setItem('userToken', token)
    return token
  } catch (error) {
    throw new Error('Invalid credentials.')
  }
}
