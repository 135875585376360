import { useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../Contexts/AuthContext'
import Content from './Content'

const StudioRoutes = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { isLoggedIn } = useAuth()

  useEffect(() => {
    if (isLoggedIn && location.pathname === '/studio') {
      navigate('/studio/game-maker')
      console.log('first : Content component mounted')
    }
  }, [isLoggedIn, location.pathname, navigate])

  return (
    <Routes>
      <Route
        path="game-maker/*"
        element={
          <Content
            activePage="game-maker"
            onMount={() => console.log('game-maker Content component mounted')}
          />
        }
      />
      <Route
        path="repository"
        element={
          <Content
            activePage="repository"
            onMount={() => console.log('repository Content component mounted')}
          />
        }
      />
    </Routes>
  )
}

export default StudioRoutes
