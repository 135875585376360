import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../Contexts/AuthContext'

const RouteGuard = ({ children }) => {
  const location = useLocation()
  const { isLoggedIn, isLoading } = useAuth()

  // Check if the auth state is still loading
  if (isLoading) {
    return <div>Loading...</div> // You can also return a loading spinner here
  }

  // If not loading, apply the route guard
  if (
    !isLoggedIn &&
    (location.pathname.startsWith('/studio') ||
      location.pathname.startsWith('/settings'))
  ) {
    console.log('Route guard: User is not logged in')
    return <Navigate to="/" replace />
  }
  console.log('User status: ', isLoggedIn)
  console.log('Route guard: User is logged in')
  return children
}

export default RouteGuard
