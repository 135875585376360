import { configureStore } from '@reduxjs/toolkit'
import quizReducer from '../Features/Quiz/quizSlice'

const store = configureStore({
  reducer: {
    triviaQuiz: quizReducer,
  },
})

export default store
